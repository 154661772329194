<template>
  <div class="home_wrapper">
    <div class="search_input">
      <van-search
        v-model="query"
        shape="round"
        placeholder="输入保险名称关键字"
        @clear="clearQuery"
        @input="inputQuery"
      >
      </van-search>
    </div>
    <div class="container">
      <div class="swiper_box">
        <swiper class="my_swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in bannerList" :key="index">
            <router-link
              :to="{
                path: '/bannerDetail',
                query: { targetUrl: item.targetUrl },
              }"
            >
              <img alt class="img" :src="item.imageUrl" />
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!--      <div class="home_menu">-->
      <!-- 暂时注释
        <div class="menu_item" @click="jumpMyOrders">
          <div class="item_icon iconfont icon-order"></div>
          <div class="item_text">我的保单</div>
                </div>-->
      <!--暂时注释-->
      <!--        <div class="menu_item" @click="jumpPolicyHolderManagement">-->
      <!--          <div class="item_icon iconfont icon-person"></div>-->
      <!--          <div class="item_text">常用投保人</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="boundary"></div>
      <!-- <van-tabs
        color="#0177D5"
        ellipsis
        title-active-color="#0177D5"
        title-inactive-color="#666666"
        v-model="active"
        animated
        swipeable
      >
        <van-tab
          :title-style="{
            'font-size': active === index ? '0.34rem' : '0.30rem',
            top: '.1rem',
          }"
          v-for="(item, index) in tabList"
          :key="index"
          :title="item"
        >
          <div class="list">
            <div v-for="(item, index) in productData" :key="index">
              <pro-item :item="item"></pro-item>
            </div>
          </div>
        </van-tab>
      </van-tabs> -->
      <div class="tab_box">家政保险</div>
      <div class="list" v-if="productData.length">
        <div v-for="(item, index) in productData" :key="index">
          <pro-item :item="item" :nextDisabled="nextDisabled"></pro-item>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import './Home.less'
import { Empty, Search, Tab, Tabs, Toast } from 'vant'
import { directive, Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ProductItem from './../../components/ProductItem'
import { getHomeData, searchKeyword, getUserInfor } from '@/api/api'


export default {
  name: 'Home',
  data() {
    return {
      // 轮播图列表
      bannerList: [],
      title: '',
      // 搜索关键词
      query: '',
      tabList: [
        '家政保险',
      ],
      active: 0,
      swiperOptions: {
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      // 商品数据
      productData: [],
	  nextDisabled:false
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    'van-search': Search,
    'van-empty': Empty,
    'pro-item': ProductItem,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  async mounted() {
	  Toast.loading({
	    message: '加载中',
	    forbidClick: true,
	    duration: 0,
	    loadingType: 'spinner'
	  })
    // this.getUserInfor()
    const data = await getHomeData({})
    this.handleData(data)
	Toast.clear()
	if(this.$route.query.pc){
		this.nextDisabled = true
	}
  },
  created() {
    this.getBanners()
  },
  methods: {
    async getUserInfor() {
      const data = await getUserInfor()
      this.userInfo = data.user
    },
    // 输入时候进行搜索
    async inputQuery() {
      await this.onSearch()
      if (this.query == undefined || this.query == '') {
        await this.clearQuery()
      }
    },
    // 清空搜索框触发 回到 初始页面
    async clearQuery() {
      const data = await getHomeData({})
      this.handleData(data)
    },
    // 搜索关键词
    async onSearch() {
      if (this.query == undefined || this.query == '') {
        return
      }
      const { productList } = await searchKeyword({
        content: this.query
      })
      this.productData = productList.jkx
      // this.productData = productList.filter(item => {
      //   return item.productName.search("家政") != -1
      // })
    },
    // 获取轮播图
    async getBanners() {
      // 获取轮播图 暂时使用微信小程序的轮播图
      // const res = await axios.get('https://yfgb.dasurebao.com/wechat/dsbjk/appActivity/getDialog')
      // console.log(res)
      let banner = {
        imageUrl: require('@/assets/banner/banner.jpg'),
        targetUrl: require('@/assets/banner/banner_detail.jpg')
      }
      this.bannerList.push(banner)
    },
    handleData(data) {
      const { active } = this.$data
      const { otherProducts, jkx, hgx } = data.productList
      // this.productData = [...jkx]
	  this.productData = [...jkx].filter(x=>x.id != 128)
      // console.log(this.productData)
    },
    jumpMyOrders() {
      this.$router.push('/myOrders')
    },
    jumpPolicyHolderManagement() {
      this.$router.push('/policyHolderManagement')
    },
  },
}
</script>
