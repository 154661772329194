<template>
  <div class="product_item" @click="goDetail">
    <img
      v-if="!item.productPictureUr"
      :src="item.productPictureUrl"
      class="img"
      alt=""
    />
    <div class="product_item_box">
      <div class="item_box_title">{{ item.productName }}</div>
      <div class="item_box_detail">
        <div class="item_box_detail_msg">{{ item.recommendReasons }}</div>
      </div>
      <div class="item_box_detail_price">
        <div>
          <span style="color: #fea41a">{{ item.lfee }}</span
          >元/起
        </div>
        <button class="btn" v-bind:disabled="nextDisabled">去投保</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'ProductItem',
  data() {
    return {}
  },
  props: ['item','nextDisabled'],
  methods: {
    goDetail() {
	  if(this.nextDisabled){
		  return
	  }
	  Toast.loading({
	    message: '加载中',
	    forbidClick: true,
	    duration: 0,
	    loadingType: 'spinner'
	  })
      this.$router.push({
        path: './ProductDetail',
        query: {
          productId: this.item.id,
		  ...this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="less">
.product_item {
  background-color: #fff;
  height: 2.6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.16rem;
  padding: 0.3rem 0.2rem 0;
  border-radius: 0.08rem;

  .img {
    height: 2rem;
    width: 1.8rem;
    background-color: #d8d8d8;
    border-radius: 0.08rem;
  }

  .product_item_box {
    margin-left: 0.34rem;
    width: 4.86rem;
    height: 2rem;

    .item_box_title {
      color: #333;
      font-size: 0.3rem;
      line-height: 0.42rem;
      margin-bottom: 0.2rem;
    }

    .item_box_detail {
      color: #666;
      font-size: 0.26rem;
      line-height: 0.4rem; //0.36rem;
      height: 0.88rem;

      .item_box_detail_msg {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .item_box_detail_price {
      font-size: 0.3rem;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        height: 0.5rem;
        width: 1.42rem;
        border-radius: 0.25rem;
        background: #28a0f4;
        color: #fff;
        font-size: 0.3rem;
        // line-height: 0.5rem;
        display: flex;
        align-items: center;
        padding-left: 0.26rem;
      }
    }
  }
}
</style>
