import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/file',
  name: 'File',
  component: () => import( /* webpackChunkName: "about" */ '../File.vue'),
  meta: {
    title: '所有前端页面'
  }
},
{
  path: '/',
  redirect: '/home'
},
{
  path: '/home',
  name: 'Home',
  component: Home,
  meta: {
    title: '首页'
  }
},
{
  path: '/productDetail',
  name: 'ProductDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/ProductDetail/ProductDetail.vue'),
  meta: {
    title: '产品详情'
  }

}, {
  path: '/policyHolderManagement',
  name: 'PolicyHolderManagement',
  component: () => import( /* webpackChunkName: "about" */ '../views/PolicyHolderManagement/PolicyHolderManagement.vue'),
  meta: {
    title: '常用投保人管理'
  }
},
{
  path: '/infoForm',
  name: 'InfoForm',
  component: () => import( /* webpackChunkName: "about" */ '../views/InfoForm/InfoForm.vue'),
  meta: {
    title: '信息填写',
  }
},
{
  path: '/confirmInfo',
  name: 'ConfirmInfo',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '../views/ConfirmInfo/ConfirmInfo.vue'),
  meta: {
    title: '信息确认'
  }
},
{
  path: '/policyDetails',
  name: 'PolicyDetails',
  component: () => import( /* webpackChunkName: "about" */ '../views/PolicyDetails/PolicyDetails.vue'),
  meta: {
    title: '保单详情页',
  }
},
{
  path: '/myOrder',
  name: 'MyOrder',
  component: () => import( /* webpackChunkName: "about" */ '../views/MyOrder/MyOrder.vue'),
  meta: {
    title: '我的保单'
  }

},
{
  path: '/electronicInvoice',
  name: 'ElectronicInvoice',
  component: () => import( /* webpackChunkName: "about" */ '../views/ElectronicInvoice/ElectronicInvoice.vue'),
  meta: {
    title: '电子发票'
  }
},
{
  path: '/writeInformation',
  name: 'WriteInformation',
  component: () => import( /* webpackChunkName: "about" */ '../views/WriteInformation/WriteInformation.vue'),
  meta: {
    title: '身份证识别',
  }
},
{
  path: '/addManagement',
  name: 'AddManagement',
  component: () => import( /* webpackChunkName: "about" */ '../views/AddManagement/AddManagement.vue'),
  meta: {
    title: '添加常用投保人'
  }
},
{
  path: '/electronicBill',
  name: 'ElectronicBill',
  component: () => import( /* webpackChunkName: "about" */ '../views/ElectronicBill/ElectronicBill.vue'),
  meta: {
    title: '电子发票'
  }
}, {
  path: '/insuranceDetails',
  name: 'InsuranceDetails',
  component: () => import( /* webpackChunkName: "about" */ '../views/InsuranceDetails/InsuranceDetails.vue'),
  meta: {
    title: '保险详情'
  }
},

{
  path: '/myOrders',
  name: 'MyOrders',
  component: () => import( /* webpackChunkName: "about" */ '../views/MyOrders/MyOrders.vue'),
  meta: {
    title: '我的保单'
  }
},
{
  path: '/modifyInsued',
  name: 'ModifyInsued',
  component: () => import( /* webpackChunkName: "about" */ '../views/ModifyInsued/ModifyInsued.vue'),
  meta: {
    title: '批改被保人'
  }
},
{
  path: '/innerPolicyDetail',
  name: 'InnerPolicyDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/InnerPolicyDetail/InnerPolicyDetail.vue'),
  meta: {
    title: '保障详情'
  }
},
{
  path: '/bannerDetail',
  name: 'BannerDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/Home/BannerDetail'),
  meta: {
    title: '轮播图详情'
  }
},
{
  path: '/policyDeclaration',
  name: 'PolicyDeclaration',
  component: () => import( /* webpackChunkName: "about" */ '../views/ProductDetail/PolicyDeclaration/PolicyDeclaration.vue'),
  meta: {
    title: '投保声明及须知'
  }
},
{
  path: '/termsDetail',
  name: 'TermsDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/ProductDetail/TermsDetail'),
  meta: {
    title: '条款列表'
  }
},
{
  path: '/bannerDetail',
  name: 'BannerDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/Home/BannerDetail/index.vue'),
  meta: {
    title: '轮播图详情'
  }
},
{
  path: '/policyDeclaration',
  name: 'PolicyDeclaration',
  component: () => import( /* webpackChunkName: "about" */ '../views/ProductDetail/PolicyDeclaration/PolicyDeclaration.vue'),
  meta: {
    title: '投保声明及须知'
  }
},
{
  path: '/termsDetail',
  name: 'TermsDetail',
  component: () => import( /* webpackChunkName: "about" */ '../views/ProductDetail/TermsDetail'),
  meta: {
    title: '条款列表'
  }
},
{
  path: '/login',
  name: 'Login',
  component: () => import( /* webpackChunkName: "about" */ '../views/Login/Login'),
  meta: {
    title: '登录'
  }
},
  {
    path: '/advertising',
    name: 'Advertising',
    component: () => import( /* webpackChunkName: "about" */ '../views/Advertising/Advertising'),
    meta: {
      title: '大树保'
    }
  },
  {
    path: '/workplan',
    name: 'WorkPlan',
    component: () => import( /* webpackChunkName: "about" */ '../views/WorkPlan/WorkPlan'),
    meta: {
      title: '排班情况'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  console.log(to);
  if (to.query.customerId) {
    localStorage.setItem('attach', JSON.stringify(to.query.customerId))
  }
  if (to.query.channelId) {
    localStorage.setItem('channelId', JSON.stringify(to.query.channelId))
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
