import {
    request
} from '../utils/request'
// 获取首页数据
const getHomeData = (data = {}) => {
    return request({
        url: '/AppDsbJkController/supreme',
        method: 'POST',
        data
    })
}

// http://ttapi.dasurebao.com/wechat/insurance/api/policyDetail
// 获取保单详情数据
const getPolicyDetailData = (data = {}) => {
    return request({
        url: '/insurance/policyDetail',
        method: 'POST',
        data
    })
}
// 获取商品详情
const getProductDetail = (data = {}) => {
    return request({
        url: '/product/getProductDetail',
        method: 'POST',
        data
    })
}
// 获取商品详情
const getProductInsuredAttr = (data = {}) => {
    return request({
        url: '/product/productInsuredAttr',
        method: 'POST',
        data
    })
}
//产品试算
const getPremium = (data = {}) => {
    return request({
        url: '/insurance/getPremium',
        method: 'POST',
        data,

    })
}
// 获取电子保单
const getElectPolicy = (query) => {
    return request({
        url: `/insurance/getElectPolicyUrl`,
        method: 'GET',
        params: query
    })
}
// 获取常用投保人列表
const getPolicyHolderManagement = (data = {}) => {
    return request({
        url: '/commonHolder/queryArray',
        method: 'POST',
        data
    })
}

// 获取保单列表
const getMyOrders = (data = {}) => {
    return request({
        url: '/HkHomeController/myPolicy',
        method: 'POST',
        data
    })
}
// 获取声明及须知
const getDeclaration = (data = {}) => {
    return request({
        url: '/product/getProductDetail',
        method: 'POST',
        data
    })
}
// 生成保单
const savePolicy = (data = {}) => {
    return request({
        url: '/newInsurance/savePolicy',
        method: 'POST',
        data,
    })
}

// 生成保单
const saveInsuredInfo = (data = {},token) => {
	// console.log('data',data,'token',token)
    return request({
		token:token,
		newBaseURL:true,
        url: 'order/v1/policy/save',
        method: 'POST',
        data,
    })
}

// 联动查询所有省级 code = 0；查询区域所属下级code=上级code
const getArea = (data = {},token) => {
	// console.log('data',data,'token',token)
    return request({
		token:token,
		newBaseURL:true,
        url: 'groundCustomer/area/list',
        method: 'GET',
        data,
    })
}

// 首页关键字搜索
const searchKeyword = (data = {}) => {
    return request({
        url: '/product/serachProduct',
        method: 'POST',
        data
    })
}

// 撤保
const undoPolicy = (data = {}) => {
    return request({
        url: '/insurance/api/undoPolicy',
        method: 'POST',
        data,
        changeAppkey: true,
        appKey: ''
    })
}

// 获取声明
const getTermsDetail = (data = {}) => {
    return request({
        url: '/product/getProductDetail',
        method: 'POST',
        data
    })
}
// ocr图片
const upLoadOcr = (data = {}) => {
    return request({
        url: '/insurance/newOcr',
        method: 'POST',
        data,
    })
}
// 支付参数
const getPayParams = (data = {}) => {
    return request({
        url: '/insurance/api/payH5',
        method: 'POST',
        data,

    })
}
// 登录
const getLogin = (data = {}) => {
    return request({
        url: '/HkLoginController/login',
        method: 'POST',
        data,
        changeAppkey: true,
        appKey: 'dsbjkgzh'
    })
}
// 获取用户信息
const getUserInfor = (data = {}) => {
    return request({
        url: '/gzhController/getUserInfor',
        method: 'POST',
        data,
        changeAppkey: true,
        appKey: 'dsbjkgzh'
    })
}

// 排班计划详情
const getSchedulingInfo = (query) => {
    return request({
        url: `/order/tk/h5/scheduling/info`,
        method: 'GET',
        params: query
    })
}

export {
    getHomeData,
    getPolicyDetailData,
    getPolicyHolderManagement,
    getMyOrders,
    getProductDetail,
    getElectPolicy,
    getProductInsuredAttr,
    getPremium,
    searchKeyword,
    savePolicy,
	saveInsuredInfo,
    undoPolicy,
    upLoadOcr,
    getPayParams,
    getDeclaration,
    getTermsDetail,
    getLogin,
    getUserInfor,
	getArea,
	getSchedulingInfo
}
