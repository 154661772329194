/*
 * @Author: your name
 * @Date: 2020-11-11 21:44:06
 * @LastEditTime: 2020-11-11 21:49:56
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \insurance-h5\src\utils\request.js
 */
import axios from 'axios'

const cusDevApiBaseUrl = 'http://1.14.161.190:8000/';
const cusProApiBaseUrl = 'https://api.bigtreedev.com/';
const cusLocApiBaseUrl = 'http://42.193.180.163:8000/';
const debug = 2;  // 1:本地开发  2:测试  3:生产

const baseConfig = {
    // 测试
    // baseURL: 'https://ttapi.dasurebao.com/wechat/dsbjk',
    // appKeyUrl: 'https://ttapi.dasurebao.com/wechat/',
    // locationHref: 'https://ttapi.dasurebao.com/test/h5',
    // 生产
	newBaseURL:debug == 3?cusProApiBaseUrl:debug == 2?cusDevApiBaseUrl:cusLocApiBaseUrl,
    baseURL: 'https://yfgb.dasurebao.com.cn/wechat/dsbjk',
    appKeyUrl: 'https://yfgb.dasurebao.com.cn/wechat/',
    locationHref: 'https://yfgb.dasurebao.com.cn/wx/h5/ins'
}
const instance = axios.create({
    timeout: 100000, // 超时时间
    baseURL: `${baseConfig.baseURL}`,
    appKeyUrl: `${baseConfig.appKeyUrl}`
})
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/json'

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
    if (config.changeAppkey) {
        config.baseURL = `${config.appKeyUrl}${config.appKey}`
    }
    // config.headers['token'] = sessionStorage.getItem('token') || ''
    // 因为返回的是二进制流，设置请求响应类型为blob，就可以在此处设置。
    if (config.url.includes('pur/contract/export')) {
        config.headers['responseType'] = 'blob'
    }
    // 文件上传，发送的是二进制流，设置请求头的'Content-Type'
    if (config.url.includes('/insurance/newOcr')) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }
	// token验证
	if (config.token) {
	    config.headers['token'] = config.token
	}
	// method
	if(config.method.toLowerCase().indexOf('get') != -1){
		 let paramsArray = [];
		 const body = config.data
		 let length= Object.keys(body).length;
		 if (length > 0) { //拼接参数
		     Object.keys(body).forEach(key => paramsArray.push(key + '=' + body[key]));
		     if (config.url.search(/\?/) === -1) {
		         config.url += '?' + paramsArray.join('&');
		     } else {
		         config.url += '&' + paramsArray.join('&');
		     }
		 }
		 config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		 delete config.data;
	}
    return config
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
})

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
    if (response.data.errorCode == '901') {
        const url = `${baseConfig.locationHref}/login`;
        const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1]);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf153975d46bb1659&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${oldPath}`
    }
    if (response.status === 200) { // 响应结果
        if (response.data.success) {
            if (response.data.resData) {
                return Promise.resolve(response.data)
            }
            if (!Object.keys(response.data.body).length) {
                return Promise.resolve(response.data)
            }
            return Promise.resolve(response.data.body)
        }else if(response.data.code === 200){
			return Promise.resolve(response.data)
		} else if(response.data.message){
			return Promise.reject(response.data)
		} else {
            return Promise.reject(response.data.msg)
        }

    } else {
        return Promise.reject(response.data.msg)
    }
}, error => {
    if (error.response) {
        return Promise.reject(error)
    } else {
        return Promise.reject(new Error('请求超时, 请刷新重试'))
    }
})

const request = (options, config = {}) => {
	if(options && options.newBaseURL){
		options.baseURL = baseConfig.newBaseURL
	}
    return new Promise((resolve, reject) => {
        instance({
            ...options,
            ...config
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}
export {
    request,
    baseConfig
}